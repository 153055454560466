@import "~bootswatch/flatly/variables";
@import "~eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker-build.scss";
@import "bootstrap-tagsinput.scss";

/* Page: 'Backend post index'
   ------------------------------------------------------------------------- */
body#admin_post_index .item-actions {
  white-space: nowrap
}

body#admin_post_index .item-actions a.btn + a.btn {
  margin-left: 4px
}

/* Page: 'Backend post show'
   ------------------------------------------------------------------------- */
body#admin_post_show .post-tags .label-default {
  background-color: #e9ecec;
  color: #6D8283;
  font-size: 16px;
  margin-right: 10px;
  padding: .4em 1em .5em;
}
body#admin_post_show .post-tags .label-default i {
  color: #95A6A7;
}
